import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { ApolloLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

// const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_SOCKET;
const GRAPHQL_ENDPOINT =
  "wss://api-gateway-xlri.techlabz.in/xlri-lms/subscriptions";

export default function websocketLink(authId, authData) {
  const wsClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
    reconnect: true,
    connectionParams: {
      Authorization: `Bearer ${authData}`,
      uuid: authId,
    },
  });

  const socketLink = new WebSocketLink(wsClient);

  const httpLink = new createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_LMS,
    headers: authData
      ? {
          Authorization: `Bearer ${authData}`,
          uuid: authId,
          "Access-Control-Allow-Origin": "*",
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
        }
      : {},
  });

  const link = ApolloLink.split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    socketLink,
    httpLink
  );

  return link;
}
